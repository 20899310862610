<template>
  <div class="import-container">
    <import-template
      :uploadUrl="uploadUrl"
      :downloadUrl="downloadUrl"
      uploadLabel="请选择导入文件"
      :autoUpload="false"
      >
      <div slot="remark" class="remark">
        1.设备房名称(必填)，30个字符以内；<br>
        2.分类(必填)，30个字符以内；<br>
        3.归属项目(必填)，30个字符以内；<br>
        4.所属组团(非必填),30个字符以内；<br>
        5.安装位置(必填),30个字符以内；<br>
        6.备注（非必填）,200个字符以内；<br>
        最大支持10MB的excel文件，超过请拆分导入<br>
        请在excel中按以上顺序填写数据
      </div>
    </import-template>
  </div>
</template>
<script>
import { ImportTemplate } from 'components/bussiness/index'
import { roomImportURL } from './api'
export default {
  components: {
    ImportTemplate
  },
  data () {
    return {
      uploadUrl: roomImportURL,
      downloadUrl: '/static/excel/设备房导入模板.xls'
    }
  },
  created () {
    this.$setBreadcrumb('导入')
  }
}
</script>
<style lang="scss" scoped>
  .remark{
    line-height: 30px;
    font-style: italic;
  }
</style>
