var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "import-container" },
    [
      _c(
        "import-template",
        {
          attrs: {
            uploadUrl: _vm.uploadUrl,
            downloadUrl: _vm.downloadUrl,
            uploadLabel: "请选择导入文件",
            autoUpload: false,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "remark",
              attrs: { slot: "remark" },
              slot: "remark",
            },
            [
              _vm._v("\n      1.设备房名称(必填)，30个字符以内；"),
              _c("br"),
              _vm._v("\n      2.分类(必填)，30个字符以内；"),
              _c("br"),
              _vm._v("\n      3.归属项目(必填)，30个字符以内；"),
              _c("br"),
              _vm._v("\n      4.所属组团(非必填),30个字符以内；"),
              _c("br"),
              _vm._v("\n      5.安装位置(必填),30个字符以内；"),
              _c("br"),
              _vm._v("\n      6.备注（非必填）,200个字符以内；"),
              _c("br"),
              _vm._v("\n      最大支持10MB的excel文件，超过请拆分导入"),
              _c("br"),
              _vm._v("\n      请在excel中按以上顺序填写数据\n    "),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }